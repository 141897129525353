<template>
  <div class="page">
    <Navbar title="分享内容" />
    <van-row>
      <van-col span="24"
        ><van-field
          v-model="message"
          rows="10"
          autosize
          type="textarea"
          show-word-limit
      /></van-col>
    </van-row>
    <van-row>
      <van-col span="24" class="button">
        <van-button
          class="btn"
          :color="COLOR"
          size="small"
          @click="copyContent()"
          >复制内容分享</van-button
        >
      </van-col>
    </van-row>
    <van-row class="tips">
      <van-col span="24" class="fc-orange"
        >注:复制完内容后，在微信或微信群里粘贴内容即可分享</van-col
      >
    </van-row>
    <van-popup :show="loadingShow" style="background-color: transparent"
      ><van-loading size="24px" :color="COLOR" vertical>加载中...</van-loading>
    </van-popup>
    <Share ref="share" />
  </div>
</template>
<script>
import { Field } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
export default {
  components: {
    Navbar: Navbar,
    Share: Share,
    [Field.name]: Field
  },
  data () {
    return {
      loadingShow: false,
      operatorCode: '',
      store: { name: '', address: '', linkPerson: '', linkPhone: '', linkWechat: '', type: '' },
      codes: '',
      type: '',
      message: ''
    }
  },
  mounted () {
    var query = this.$route.query
    this.codes = query.codes
    this.type = query.type
    this.operatorCode = window.sessionStorage.getItem(this.SESSION_OPERATOR)
    this.retrieveOperator()
    this.initShare()
  },
  methods: {
    async retrieveRctShare () {
      var user = window.sessionStorage.getItem(this.SESSION_USER)
      var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
      var pd = { codes: this.codes, operatorCode: this.operatorCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/recruit/task/retrieveShareTask', this.$qs.stringify(pd))
      if (res.status === '200') {
        var message = '好工作不求人，就上微职客\r\n热门职位推荐\r\n\r\n'
        message += res.data.message
        message += '服务商：' + this.store.name + '\r\n'
        message += '地   址：' + this.store.address + '\r\n'
        message += '联系方式：' + this.store.linkPhone + '(' + this.store.linkPerson + ')\r\n\r\n'
        message += '职位详情和更多职位请点击:\r\nhttps://moc.utopanet.com/recruit/home?f=1'
        if (user !== undefined && user !== null && user !== '') {
          message += '&upper=' + user
        }
        if (operator !== undefined && operator !== null && operator !== '') {
          message += '&operator=' + operator
        }
        this.message = message
        this.loadingShow = false
      }
    },
    initShare () {
      var operator = this.operatorCode
      var title = '微职客分享助手'
      var desc = '快速帮您生成分享内容，为营销推广赋能'
      var link = 'https://moc.utopanet.com/share?f=1'
      if (operator !== undefined && operator !== null && operator !== '') {
        link = link + '&operator=' + operator
      }
      var logo = 'https://moc.utopanet.com/static/logo.png'
      this.$refs.share.init(title, desc, link, logo)
    },
    async retrieveOperator () {
      this.loadingShow = true
      var pd = { operatorCode: this.operatorCode }
      const { data: res } = await this.$http.post(this.UMS_URL + '/operate/operator/retrieveOperator', this.$qs.stringify(pd))
      if (res.status === '200') {
        console.log(res.data)
        this.store = res.data
        if (this.type === 'RCT') {
          this.retrieveRctShare()
        }
      }
    },
    copyContent () {
      var ta = document.createElement('textarea')
      ta.value = this.message
      document.body.appendChild(ta)
      ta.select()
      document.execCommand('copy')
      document.body.removeChild(ta)
      this.$dialog.alert({
        title: '提示信息',
        message: '复制成功,请在微信或微信群粘贴分享'
      })
    }
  }
}
</script>
<style lang="less" scoped>
.button {
  border-top: 1px solid #eee;
  .btn {
    margin: 20px 0px 10px 0px;
  }
}
.tips {
  margin-bottom: 30px;
}
</style>
